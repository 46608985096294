import React, { useContext } from "react"

import Layout from "../components/layout"
import PageContext from "../context"
// import SEO from "../components/seo"

import icFaster from "../images/ic-faster.svg"
import icFeature from "../images/ic-new-feature.svg"
import icProduct from "../images/ic-new-product.svg"
import icRisk from "../images/ic-risk.svg"
import icPrototype from "../images/ic-prototype.svg"
import icReport from "../images/ic-report.svg"
import icBusiness from "../images/ic-business.svg"
import icFast from "../images/ic-fast.svg"
import icPredictable from "../images/ic-predictable.svg"

import logoGoogle from "../images/logo-google.svg"
import logoSlack from "../images/logo-slack.svg"
import logoAirbnb from "../images/logo-airbnb.svg"
import logoMicrosoft from "../images/logo-microsoft.svg"
import logoMedium from "../images/logo-medium.svg"
import logoUber from "../images/logo-uber.svg"
import logoFacebook from "../images/logo-facebook.svg"
import logoPaypal from "../images/logo-paypal.svg"

import "./index.scss"
import "./home.scss"

const IndexPage = () => {
  const handleCTA = useContext(PageContext)
  console.log(handleCTA)
  return (
    <Layout
      className="layout home"
      title={"pageTitle"}
      desc={"pageDescription"}
      linkBack
    >
      <PageContext.Consumer>
        {data => (
          <section className="hero">
            <div className="content">
              <h1>Rocket boost your next software project</h1>
              <p>
                Hire a team of experts to build an MVP and test it with real
                users in just 3 weeks
              </p>
              <button className="secondary" onClick={data.scrollToContact}>
                Book a call
              </button>
            </div>
          </section>
        )}
      </PageContext.Consumer>
      <section className="why">
        <h2>Why run a sprint?</h2>
        <div>
          <div className="item">
            <img className="ic" src={icRisk} alt="risk icon" />
            <h3>Reduce risk</h3>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://medium.com/swlh/the-top-10-reasons-startups-fail-ab3196d70568"
              >
                The #1 reason why startups fail
              </a>
              , is because they built something no one needs. Reduce this risk
              by meeting real in 3 weeks, not 3 months
            </p>
          </div>
          <div className="item">
            <img className="ic" src={icFaster} alt="ship faster icon" />
            <h3>Go to market faster</h3>
            <p>
              Get input from all stakeholders at the same time to cut months of
              back and forth, giving you a longer runway
            </p>
          </div>
        </div>
      </section>
      <section className="covid">
        <div className="item">
          <h2>Why Now?</h2>
          <span className="updated">Last Update: July 2020</span>
          <p>
            COVID-19 has surfaced unprecedented challenges - New products are
            built, new features are released... However,{" "}
            <strong>business is not as usual</strong>, we must be more
            delibarate in our decisions, and more considerate in our actions.
          </p>
        </div>
        <div className="content">
          <div className="item">
            <img className="ic" src={icBusiness} alt="business icon" />
            <h3>Business-Driven</h3>
            <p>
              The sprint is highly focused on generating tangible results to
              help move the business forward.
            </p>
          </div>
          <div className="item">
            <img className="ic" src={icFast} alt="fast icon" />
            <h3>Fast Results</h3>
            <p>
              The sprint is deliberately fast and intensive, and it aims to
              provide results <strong>you can implement immediately</strong>.
            </p>
          </div>
          <div className="item">
            <img className="ic" src={icPredictable} alt="predictable icon" />
            <h3>Predictable</h3>
            <p>
              The sprint runs on a predefined schedule, and ends with predefined
              deliverables.
            </p>
          </div>
        </div>
      </section>
      <section className="who">
        <p>Other companies running Design Sprints</p>
        <section className="logos">
          <img src={logoGoogle} alt="Google logo" />
          <img src={logoSlack} alt="Slack logo" />
          <img src={logoAirbnb} alt="Airbnb logo" />
          <img src={logoMicrosoft} alt="Microsoft logo" />
          <img src={logoMedium} alt="Medium logo" />
          <img src={logoUber} alt="Uber logo" />
          <img src={logoFacebook} alt="Facebook logo" />
          <img src={logoPaypal} alt="Paypal logo" />
        </section>
      </section>
      <section className="when strip">
        <section className="content">
          <h2>When is the right time?</h2>
          <div className="items">
            <div className="item">
              <img className="ic" src={icProduct} alt="product icon" />
              <h3>Launch a new product</h3>
              <p>
                Validate your assumptions before you write a single line of code
              </p>
            </div>
            <div className="item">
              <img className="ic" src={icFeature} alt="feature icon" />
              <h3>Add a BIG feature</h3>
              <p>
                Align the team on why and what they’re adding to the product
              </p>
            </div>
            <div className="item">
              <img className="ic" src={icPredictable} alt="redesign icon" />
              <h3>Redesign an existing product</h3>
              <p>
                Get input from all stakeholders to better redesign an existing
                product
              </p>
            </div>
          </div>
        </section>
      </section>
      <section className="how">
        <h2>How it works</h2>
        <div className="week">
          <div className="card week1" />
          <div className="content">
            <h3>Week 1</h3>
            <p>
              We start out by understanding your company and the domain in which
              you operate. In this week we will unpack the current sitation -
              what’s going well and what needs to be improved.
            </p>
            <ul>
              <li>Get to know the team</li>
              <li>Conduct Competitor Analysis</li>
              <li>1 on 1 interviews with the team</li>
              <li>Create the initial user journey map</li>
            </ul>
          </div>
        </div>
        <div className="week">
          <div className="card week2" />
          <div className="content">
            <h3>Week 2</h3>
            <p>
              Design Sprints were pioneered at Google, and had since transformed
              how Airbnb, Slack, Lego, and many others approach innovation. In
              this week we will go from idea, to product all the way to testing
              with real users
            </p>
            <ul>
              <li>Get clarity into what you’re building</li>
              <li>Align your team on the mission</li>
              <li>Produce a working prototype</li>
              <li>Get feedback from real customers</li>
            </ul>
          </div>
        </div>
        <div className="week">
          <div className="card week3" />
          <div className="content">
            <h3>Week 3</h3>
            <p>
              Having successfully completed a design sprint, we will gather all
              the findings, prioritize, update the prototype, and form
              actionable next steps.
            </p>
            <ul>
              <li>Process feedback from last week</li>
              <li>Update key screens accordingly</li>
              <li>Create a summarised report</li>
              <li>Create a prioritized list of next steps</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="what strip">
        <section className="content">
          <h2>What do I actually get</h2>
          <div className="items">
            <div className="item">
              <img className="ic" src={icPrototype} alt="ship faster icon" />
              <h3>Tested Prototype</h3>
              <p>
                By the end of the first week, you would have fast forwarded into
                the future to see real users interact with a prototype of your
                finished product
              </p>
            </div>
            <div className="item">
              <img className="ic" src={icReport} alt="ship faster icon" />
              <h3>Summarised Report</h3>
              <p>
                After the second week, you will get a concise report detailing
                all the important conclusions, as well as actionable next steps
                for your Design & Engineering teams
              </p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}

export default IndexPage
